import React from "react";
import Header from "../Header";

export default function Page() {
  return (
    <>
      <Header />
    </>
  );
}
